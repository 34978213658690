import React from "react"
import {isChrome,isFirefox,isIeEdge} from "@tightrope/lpscripts/browserdetect"
import Styles from "./css/ctadisclosurecb2.module.scss"
class CwsDisclosure extends React.Component {
  constructor(props){
    super(props);
    this.state = ({data: {}});
  }
  componentDidMount(){
    if(this.props.data){
      this.state = {
        data: this.props.data
      }
    }
  }
  render() {
    let browser = null;
    if(isChrome()){
      browser = 'chrome';
    }
    else if(isFirefox()){
      browser = 'firefox';
    }
    else{
      browser = 'other';
    }
    return (
        <div id = {Styles.ctadisclaimer}>
          <p>This free extension will update this browser's new tab page. By continuing, you acknowledge the <a href = "/terms-of-service/" target = "_blank" className={Styles.bold}>Terms of Service</a> and <a href = "/privacy-policy/" target = "_blank" className={Styles.bold}>Privacy Policy</a>, and that you may be presented with more optional offers.</p>
        </div>
    )
  }
}
export default CwsDisclosure
